import { createSlice } from "@reduxjs/toolkit";
import { OrderSuspendAction, OrdersDetailsAction, OrdersListAction, getDeliveryPartnerListAction } from "../Action/OrdersAction";

const initialState = {
    ordersList: [],
    ordersDetails: {},
    partnersList: [],
    orderPassStatus: "idle",
}

const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(OrdersListAction.fulfilled, (state, action) => {
                state.ordersList = action?.payload;
            })
            .addCase(OrdersDetailsAction.fulfilled, (state, action) => {
                state.ordersDetails = action?.payload;
            })
            .addCase(OrderSuspendAction.fulfilled, (state, action) => {
                state.orderPassStatus = "succeeded";
            })
            .addCase(getDeliveryPartnerListAction.fulfilled, (state, action) => {
                state.partnersList = action?.payload;
            })
    },
});

export default ordersSlice.reducer;
import { createSlice } from "@reduxjs/toolkit";
import { contactDetailsAction, contactListAction } from "../Action/ContactAction";

const initialState = {
    contactList: [],
    contactDetails: {},
    contactPassStatus: "idle"
}

const contactSlice = createSlice({
    name: 'contact',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(contactListAction.fulfilled, (state, action) => {
                state.contactList = action?.payload;
            })
            .addCase(contactDetailsAction.fulfilled, (state, action) => {
                state.contactDetails = action?.payload;
            });
           
    },
});

export default contactSlice.reducer;
import React, { lazy, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PublicRoutesSuspense from "./Components/Route/PublicRoutesSuspense.js";
import PrivateRoutesSuspense from "./Components/Route/PrivateRoutesSuspense.js";
import ProtectedRoute from "./Components/Route/ProtectedRoute.js";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import useFirebaseToken from "./CustomHook/useFirebaseToken.js";
import { getMessaging, onMessage } from "@firebase/messaging";
import { initializeApp } from "@firebase/app";

const Login = lazy(() => import("./pages/Login"));
const UserManagement = lazy(() => import("./pages/UserManagement"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ManageServices = lazy(() => import("./pages/ManageServices"));
const ManageChecklist = lazy(() => import("./pages/ManageChecklist"));
const CustomerProfile = lazy(() => import("./pages/CustomerProfile"));
const AddNotification = lazy(() => import("./pages/AddNotification"));
const Supportchat = lazy(() => import("./pages/SupportChat"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Support = lazy(() => import("./pages/Support"));
const CommunityMangement = lazy(() => import("./pages/CommunityMangement"));
const Notifications = lazy(() => import("./pages/Notifications"));
const CreateCommunity = lazy(() => import("./pages/CreateCommunity"));
const CreateMail = lazy(() => import("./pages/CreateMail"));
const ManageCourses = lazy(() => import("./pages/ManageCourses"));
const ManageEvents = lazy(() => import("./pages/ManageEvents"));
const TicketDetail = lazy(() => import("./pages/TicketDetail"));
const UserChecklist = lazy(() => import("./pages/UserChecklist"));
const CreateCourse = lazy(() => import("./pages/CreateCourse"));
const CourseDetails = lazy(() => import("./pages/CourseDetails"));
const CreateEvent = lazy(() => import("./pages/CreateEvent"));
const CreateChecklist = lazy(() => import("./pages/CreateChecklist"));
const ChecklistDetails = lazy(() => import("./pages/ChecklistDetails"));
const UserProfile = lazy(() => import("./pages/UserProfile"));
const UserCommunities = lazy(() => import("./pages/UserCommunities"));
const UserCourses = lazy(() => import("./pages/UserCourses"));
const CommunityDetails = lazy(() => import("./pages/CommunityDetails"));
const CommunityName = lazy(() => import("./pages/CommunityName"));
const VerifyShop = lazy(() => import("./pages/VerifyShop"));
const VerifyShopInfo = lazy(() => import("./pages/VerifyShopInfo"));
const DeliveryPartner = lazy(() => import("./pages/DeliveryPartner"));
const DeliveryPartnerProfile = lazy(() => import("./pages/DeliveryPartnerProfile"));
const DeliveryOrders = lazy(() => import("./pages/DeliveryOrders"));
const DeliveryOrderDetail = lazy(() => import("./pages/DeliveryOrderDetail"));
const Shop = lazy(() => import("./pages/Shop"));
const OrderDetail = lazy(() => import("./pages/OrderDetail"));
const Orders = lazy(() => import("./pages/Orders"));
const ProductDetail = lazy(() => import("./pages/Shop"));
const ProductListed = lazy(() => import("./pages/ProductListed"));
const ShopInfo = lazy(() => import("./pages/ShopInfo"));
const VerifyDeliveryPartner = lazy(() => import("./pages/VerifyDeliveryPartner"));
const VerifyDeliveryPartnerProfile = lazy(() => import("./pages/VerifyDeliveryPartnerProfile"));
const OrderManagementDetail = lazy(() => import("./pages/OrderManagementDetail"));
const OrderManagementDetailConfirmed = lazy(() => import("./pages/OrderManagementDetailConfirmed"));
const VerifyProducts = lazy(() => import("./pages/VerifyProducts"));
const ManagePromoCode = lazy(() => import("./pages/ManagePromocode"));
const AddPromoCode = lazy(() => import("./pages/AddPromocode"));
const ManageCategory = lazy(() => import("./pages/ManageCategory"));
const ManageProductCategory = lazy(() => import("./pages/ManageProductCategory"));
const ManageFlavor = lazy(() => import("./pages/ManageFlavor"));
const AddFlavor = lazy(() => import("./pages/AddFlavor"));
const ManageDeliveryCharges = lazy(() => import("./pages/ManageDeliveryCharges"));
const ProductDetails = lazy(() => import("./pages/ProductDetails"));
const LoginOtp = lazy(() => import("./pages/LoginOtp.js"));
const ChangePassword = lazy(() => import("./pages/ChangePassword.js"));
const ResetPassword = lazy(() => import("./pages/ResetPassword.js"));
const ManageCommission = lazy(() => import("./pages/ManageCommission.js"));
const EditCommission = lazy(() => import("./pages/EditCommission.js"));
const VerifyProductDetail = lazy(() => import("./pages/VerifyProductDetail.js"));

const firebaseConfig = {
  apiKey: "AIzaSyCpqa5f5gl95zw_iBmZWPPuCe0ENNOm_DQ",
  authDomain: "milcakepartner.firebaseapp.com",
  projectId: "milcakepartner",
  storageBucket: "milcakepartner.appspot.com",
  messagingSenderId: "626397822942",
  appId: "1:626397822942:web:bb1e2f1de742245203e74f"
};


function App() {
  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);

  // useEffect(() => {
  //   const unsubscribe = onMessage(messaging, (payload) => {
  //     console.log('Message received. ', payload);
  //     toast.success(`${payload.notification.title}: ${payload.notification.body}`);
  //   });
  //   return () => {
  //     unsubscribe();
  //   };
  // }, []);


  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<PublicRoutesSuspense> <Login /> </PublicRoutesSuspense>} />
          <Route path="/Forgot-Password" element={<PublicRoutesSuspense> <ForgotPassword /> </PublicRoutesSuspense>} />
          <Route path="/Reset-Password/:id" element={<PublicRoutesSuspense> <ResetPassword /> </PublicRoutesSuspense>} />
          <Route path="/Otp/:otp/:email" element={<PublicRoutesSuspense> <LoginOtp /></PublicRoutesSuspense>} />

          <Route element={<ProtectedRoute />}>
            <Route path="/Dashboard" element={<PrivateRoutesSuspense> <Dashboard /> </PrivateRoutesSuspense>} />
            <Route path="/CommunityName" element={<PrivateRoutesSuspense> <CommunityName /> </PrivateRoutesSuspense>} />
            <Route path="/CommunityDetails" element={<PrivateRoutesSuspense> <CommunityDetails /> </PrivateRoutesSuspense>} />
            <Route path="/UserCourses" element={<PrivateRoutesSuspense> <UserCourses /> </PrivateRoutesSuspense>} />
            <Route path="/UserCommunities" element={<PrivateRoutesSuspense> <UserCommunities /> </PrivateRoutesSuspense>} />
            <Route path="/UserProfile/:id" element={<PrivateRoutesSuspense> <UserProfile /> </PrivateRoutesSuspense>} />
            <Route path="/ChecklistDetails" element={<PrivateRoutesSuspense> <ChecklistDetails /> </PrivateRoutesSuspense>} />
            <Route path="/CreateChecklist" element={<PrivateRoutesSuspense> <CreateChecklist /> </PrivateRoutesSuspense>} />
            <Route path="/CreateEvent" element={<PrivateRoutesSuspense> <CreateEvent /> </PrivateRoutesSuspense>} />
            <Route path="/CourseDetails" element={<PrivateRoutesSuspense> <CourseDetails /> </PrivateRoutesSuspense>} />
            <Route path="/CreateCourse" element={<PrivateRoutesSuspense> <CreateCourse /> </PrivateRoutesSuspense>} />
            <Route path="/AddNotification" element={<PrivateRoutesSuspense> <AddNotification /> </PrivateRoutesSuspense>} />
            <Route path="/UserChecklist" element={<PrivateRoutesSuspense> <UserChecklist /> </PrivateRoutesSuspense>} />
            <Route path="/TicketDetail" element={<PrivateRoutesSuspense> <TicketDetail /> </PrivateRoutesSuspense>} />
            <Route path="/ChangePassword" element={<PrivateRoutesSuspense> <ChangePassword /> </PrivateRoutesSuspense>} />

            <Route path="/ManageEvents" element={<PrivateRoutesSuspense> <ManageEvents /> </PrivateRoutesSuspense>} />
            <Route path="/CreateMail" element={<PrivateRoutesSuspense> <CreateMail /> </PrivateRoutesSuspense>} />
            <Route path="/ManageCourses" element={<PrivateRoutesSuspense> <ManageCourses /> </PrivateRoutesSuspense>} />
            <Route path="/UserManagement" element={<PrivateRoutesSuspense> <UserManagement /> </PrivateRoutesSuspense>} />
            <Route path="/Support" element={<PrivateRoutesSuspense> <Support /> </PrivateRoutesSuspense>} />

            <Route path="/CustomerProfile" element={<PrivateRoutesSuspense> <CustomerProfile /> </PrivateRoutesSuspense>} />
            <Route path="/Supportchat/:id" element={<PrivateRoutesSuspense> <Supportchat /> </PrivateRoutesSuspense>} />
            <Route path="/ManageServices" element={<PrivateRoutesSuspense> <ManageServices /> </PrivateRoutesSuspense>} />
            <Route path="/CommunityMangement" element={<PrivateRoutesSuspense> <CommunityMangement /> </PrivateRoutesSuspense>} />

            <Route path="/CreateCommunity" element={<PrivateRoutesSuspense> <CreateCommunity /> </PrivateRoutesSuspense>} />
            <Route path="/order-management" element={<PrivateRoutesSuspense> <ManageChecklist /> </PrivateRoutesSuspense>} />

            <Route path="/shop" element={<PrivateRoutesSuspense> <Shop /> </PrivateRoutesSuspense>} />
            <Route path="/shop-info/:id" element={<PrivateRoutesSuspense> <ShopInfo /> </PrivateRoutesSuspense>} />
            <Route path="/product-listed" element={<PrivateRoutesSuspense> <ProductListed /> </PrivateRoutesSuspense>} />
            <Route path="/product-detail" element={<PrivateRoutesSuspense> <ProductDetail /> </PrivateRoutesSuspense>} />
            <Route path="/orders" element={<PrivateRoutesSuspense> <Orders /> </PrivateRoutesSuspense>} />
            <Route path="/order-detail/:id" element={<PrivateRoutesSuspense> <OrderDetail /> </PrivateRoutesSuspense>} />
            <Route path="/verify-shop" element={<PrivateRoutesSuspense> <VerifyShop /> </PrivateRoutesSuspense>} />
            <Route path="/verify-shop-info/:id" element={<PrivateRoutesSuspense> <VerifyShopInfo /> </PrivateRoutesSuspense>} />
            <Route path="/delivery-partner" element={<PrivateRoutesSuspense> <DeliveryPartner /> </PrivateRoutesSuspense>} />
            <Route
              path="/delivery-partner-profile/:id"
              element={<PrivateRoutesSuspense> <DeliveryPartnerProfile /> </PrivateRoutesSuspense>}
            />
            <Route path="/delivery-orders" element={<PrivateRoutesSuspense> <DeliveryOrders /> </PrivateRoutesSuspense>} />
            <Route
              path="/delivery-orders-detail/:id"
              element={<PrivateRoutesSuspense> <DeliveryOrderDetail /> </PrivateRoutesSuspense>}
            />
            <Route
              path="/verify-delivery-partner"
              element={<PrivateRoutesSuspense> <VerifyDeliveryPartner /> </PrivateRoutesSuspense>}
            />
            <Route path="/verify-delivery-partner-profile/:id"
              element={<PrivateRoutesSuspense> <VerifyDeliveryPartnerProfile /> </PrivateRoutesSuspense>} />
            <Route
              path="/order-management-detail/:id"
              element={<PrivateRoutesSuspense> <OrderManagementDetail /> </PrivateRoutesSuspense>}
            />
            <Route
              path="/order-management-detail-confirmed"
              element={<PrivateRoutesSuspense> <OrderManagementDetailConfirmed /> </PrivateRoutesSuspense>} />
            <Route path="/verify-products" element={<PrivateRoutesSuspense> <VerifyProducts /> </PrivateRoutesSuspense>} />
            <Route path="/Notifications" element={<PrivateRoutesSuspense> <Notifications /> </PrivateRoutesSuspense>} />
            <Route path="/manage-promocode" element={<PrivateRoutesSuspense> <ManagePromoCode /> </PrivateRoutesSuspense>} />
            <Route path="/add-promocode" element={<PrivateRoutesSuspense> <AddPromoCode /> </PrivateRoutesSuspense>} />
            <Route path="/manage-category" element={<PrivateRoutesSuspense> <ManageCategory /> </PrivateRoutesSuspense>} />
            <Route path="/manage-commission" element={<PrivateRoutesSuspense> <ManageCommission /> </PrivateRoutesSuspense>} />
            <Route path="/edit-commission" element={<PrivateRoutesSuspense> <EditCommission /> </PrivateRoutesSuspense>} />


            <Route
              path="/manage-product-category"
              element={<PrivateRoutesSuspense> <ManageProductCategory /> </PrivateRoutesSuspense>}
            />
            <Route path="/manage-flavor" element={<PrivateRoutesSuspense> <ManageFlavor /> </PrivateRoutesSuspense>} />
            <Route path="/add-flavor" element={<PrivateRoutesSuspense> <AddFlavor /> </PrivateRoutesSuspense>} />
            <Route
              path="/manage-delivery-charges"
              element={<PrivateRoutesSuspense> <ManageDeliveryCharges /> </PrivateRoutesSuspense>}
            />
            <Route path="/product-details/:id" element={<PrivateRoutesSuspense> <ProductDetails /> </PrivateRoutesSuspense>} />
            <Route path="/verify-product-details/:id" element={<PrivateRoutesSuspense> <VerifyProductDetail /> </PrivateRoutesSuspense>} />

          </Route>
        </Routes>
        <ToastContainer limit={1} />
      </BrowserRouter>
    </div>
  );
}

export default App;

import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../service/Api";
import { constructQueryString } from "../../utils/CommonFunction";

const loginAction = createAsyncThunk(
    'login', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('/login', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const verifyOtpAction = createAsyncThunk(
    'verify', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('/forgot-verify', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const ForgotPasswordAction = createAsyncThunk(
    'forgot-password', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('/forgot-password', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const forgotverifyEmailAction = createAsyncThunk(
    'forgot-verifyEmail', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('/auth/forgot-verifyEmail', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })


const resetpasswordAction = createAsyncThunk(
    'reset-password', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.put('/auth/reset-password', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const adminResetPasswordAction = createAsyncThunk(
    'reset-password', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('/set-password', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const adminSubResetPasswordAction = createAsyncThunk(
    'reset-password', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post('/sub/reset-password', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })


const changePasswordAction = createAsyncThunk(
    'changePassword', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.patch('/change-password', reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })

const dashboardAction = createAsyncThunk(
    'dashboard', async (reqBody, thunkAPI) => {
        try {
            const queryString = constructQueryString(reqBody);
            const response = await Api.get(`/dashboard?${queryString}`, reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })




export {
    loginAction,
    verifyOtpAction,
    ForgotPasswordAction,
    forgotverifyEmailAction,
    resetpasswordAction,
    adminResetPasswordAction,
    adminSubResetPasswordAction,
    changePasswordAction,
    dashboardAction
};










import { createSlice } from "@reduxjs/toolkit";
import { shopDeleteAction, shopDetailsAction, shopListAction, shopOrderDetailsAction, shopOrderListAction } from "../Action/ShopAction";
const initialState = {
    shopList: [],
    shopOrderList: [],
    shopDetails: {},
    shopOrderDetails: {},
    shopPassStatus: "idle"
}

const shopSlice = createSlice({
    name: 'shop',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(shopListAction.fulfilled, (state, action) => {
                state.shopList = action?.payload;
            })
            .addCase(shopDetailsAction.fulfilled, (state, action) => {
                state.shopDetails = action?.payload;
            })
            .addCase(shopDeleteAction.fulfilled, (state, action) => {
                state.shopPassStatus = 'succeeded';
            })
            .addCase(shopOrderListAction.fulfilled, (state, action) => {
                state.shopOrderList = action?.payload;
            })
            .addCase(shopOrderDetailsAction.fulfilled, (state, action) => {
                state.shopOrderDetails = action?.payload;
            })
    },
});

export default shopSlice.reducer;
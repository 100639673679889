import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../service/Api";
import { constructQueryString } from "../../utils/CommonFunction";

const promoListAction = createAsyncThunk(
    'promo/list', async (reqBody, thunkAPI) => {
        try {
            const queryString = constructQueryString(reqBody);
            const response = await Api.get(`/promocode${queryString ? `?${queryString}` : ""}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const promoDetailsAction = createAsyncThunk(
    'promo/details', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get(`/promocode/${reqBody.id}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const promoDeleteAction = createAsyncThunk(
    'promo/delete', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.delete(`/promocode/${reqBody}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const addPromoAction = createAsyncThunk(
    'flavor/add', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.post(`/promocode`, reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const updatePromoAction = createAsyncThunk(
    'flavor/edit', async (reqBody, thunkAPI) => {
        try {
            const { id, ...payload } = reqBody;
            const response = await Api.put(`/promocode/${id}`, payload)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })


export {
    promoListAction,
    promoDetailsAction,
    promoDeleteAction,
    addPromoAction,
    updatePromoAction
};
import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../service/Api";
import { constructQueryString } from "../../utils/CommonFunction";

const contactListAction = createAsyncThunk(
    'contact/list', async (reqBody, thunkAPI) => {
        try {
            const queryString = constructQueryString(reqBody);
            const response = await Api.get(`/support-chat${queryString ? `?${queryString}` : ""}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const contactDetailsAction = createAsyncThunk(
    'contact/details', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get(`/support-chat/${reqBody.id}`)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

const updateContactAction = createAsyncThunk(
    'contact/edit', async (reqBody, thunkAPI) => {
        try {
            const { id, ...payload } = reqBody;
            const response = await Api.patch(`/support-chat/${id}`, payload)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })


export {
    contactListAction,
    contactDetailsAction,
    updateContactAction
};